
.aboutme {

	display: flex;
	flex-direction: column;
	padding-top: 20px;

	@media(min-width: 1000px) {
		flex-direction: row;
	}
}

.profile {
	&__photo {
		display: flex;
		justify-content: center;

		@media(min-width: 1000px) {
			width: 40%;
			align-items: start;
		}

		img {
			width: 300px;
			border-radius: 50%;
		}
	}

	&__description {
		width: 100%;
		padding-left: 10%;
		padding-right: 10%;

		@media(min-width: 1000px) {
			width: 60%;
		}
	}
}
