
.skills {

	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

		&__item {
			width: 80vw;
			display: flex;
		margin: 5px;
	}

	&__title {
		background-color: $highlight;
		color: black;
		width: 100px;
		padding-left: 10px;
	}

	&__bar {
		display: flex;
		background-color: $grey;
		width: calc(100% - 150px);
		position: relative;
		align-items: center;
		justify-content: left;

		&-full {
			display: block;
			background-color: #569351;
			height: 100%;
			border-radius: 0 5px 5px 0;
		}
	}

	&__level {
		background-color: $grey;
		width: 50px;
		padding-right: 10px;
		text-align: right;
	}
}
