$black: rgb(23, 23, 23);
$highlight: #80C87B;
$grey: #666;

* {
	box-sizing: border-box;
}


html,
body {
  min-height: 100%;
  min-width: 100%;
  background-color: $black;
	color: #efefef;
	font-family: 'Archivo Narrow', sans-serif;
	margin: 0;
}

body > section {
	margin-left: 20px;
	margin-right: 20px;
}


.hero {
  height: 100vh;

	font-family: 'Archivo Narrow', sans-serif;
	font-size: 23pt;
	font-weight: bold;
	letter-spacing: 3px;

	background-image: url("../images/banner.jpg");
	background-size: cover;

	.overlay {
		width: 100%;
		height: 100%;
		background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%);
		padding: 15px;


		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

}

h1,
h2 {
	font-family: 'Julius Sans One', sans-serif;
}

h1 {
	font-size: 62pt;
}

@import "aboutme.scss";

@import "skills.scss";

@import "projects.scss";

@import "joblist.scss";

@import "social.scss";
