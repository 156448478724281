.joblist {
}

.job {

	--year-space: 80px;
	--divider-padding: 15px;


	position: relative;
	margin-left: calc(var(--year-space) + var(--divider-padding));
	width: calc(90% - var(--year-space) + var(--divider-padding));
	margin-bottom: 20px;
	padding: 5px;
	border-radius: 0;
	border: 1px solid  #6e6e6e;
	background-color: #1e1e1e;

	@media(min-width: 1000px) {
		--year-space: 150px;
		--divider-padding: 60px;
	}


	&::before {
    border-left: 1px dashed #6e6e6e;
    content: "";
    width: 1px;
    position: absolute;
    left: calc(0px - var(--divider-padding));
    height: calc(100% + 18px);

		@media(min-width: 1000px) {
			left: -46px;
		}
	}

	&__year {


		position: absolute;
		left: calc(0px - (var(--year-space) + var(--divider-padding)));
		top: 12px;
		width: var(--year-space);
		font-size: 1.1rem;
		font-family: 'Julius Sans One', sans-serif;


		@media(min-width: 100px) {
			font-size: 1.2rem;
		}
	}

	&__title {
		font-family: 'Julius Sans One', sans-serif;
	}

	&__employer {
		font-size: 1rem;
		font-style: italic;
		margin-left: 20px;
	}

}
