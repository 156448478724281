
%social-icon {
		display: block;
		height: 75px;
		width: 75px;
		font-size: 0;
		background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
		margin: 20px;
}

.social {

	h2 {
		width: 0;
		height: 0;
		font-size: 0;
	}
	
	&__list {
		display: flex;
		height: 200px;
		align-items: center;
		justify-content: center;
	}

	&__github {
		@extend %social-icon;
		background-image: url("../images/octocat.svg");
	}

	&__linkedin {
		@extend %social-icon;
		background-image: url("../images/linkedin.svg");
	}
}
