.projects {
	display: flex;
	flex-wrap: wrap;
}

.project 
{
	$project-width: 400px;
	$project-height: 300px;
	$z-index: 10;

	width: $project-width;
	height: $project-height;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	&__image,
 figcaption	{
		position: absolute;
		width: $project-width;
		height: $project-height;
		max-width: 100%;
	}

	figcaption {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.8);
		color: black;
		opacity: 0;
		transition: opacity 0.5s;

		&:focus,
		&:hover {
			opacity: 1;
		}
	}

	&__link {
		display: block;
		width: 100px;
		height: 40px;
		color: $highlight;
		border: 1px solid $highlight;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

}
